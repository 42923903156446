// Variable overrides
$theme-colors: (
  "primary": #567ec8,
  "secondary": #1d5d83,
  "accent": #2882b8,
  "hint": #34a1e1,
);
.c-sidebar-nav {
  background-color: #415f96;
}
.c-sidebar-nav-title {
  background-color: #344251;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
